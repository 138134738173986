import { RouteRecordRaw } from "vue-router";

const sidebarName = "SidebarSupport";

export const supportRoutes: RouteRecordRaw[] = [
  {
    path: "/support",
    component: () => import("@/views/common/Home.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/support/samplingPoints",
    component: () => import("@/views/support/SamplingPoints.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/support/ia",
    component: () => import("@/views/support/upload-image.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/support/samplingPoints/:containerid",
    component: () => import("@/views/support/SamplingPointDetail.view.vue"),
    //component: () => import("@/views/support/ContainerDetail.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/support/samplingPoints/:containerid/tmoa",
    component: () => import("@/views/support/InfoTmoa.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },

  {
    path: "/support/alerts",
    component: () => import("@/views/support/Alerts.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/support/graph",
    component: () => import("@/views/support/Graph.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/support/generatedAlerts",
    component: () => import("@/views/common/GeneratedAlerts.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/support/report",
    //component: () => import("@/views/common/Report.view.vue"),
    // Versión nueva, comentar al commit
    component: () => import("@/views/common/Report_new.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  // -Importar versión anterior comentar para probar
  // {
  //   path: "/support/import",
  //   component: () => import("@/views/support/importData.view.vue"),
  //   meta: {
  //     layout: "LayoutDefault",
  //     sidebar: sidebarName,
  //   },
  // },
  // - Importar versión nueva descomentar para probar
  {
    path: "/support/import",
    component: () => import("@/views/support/Import.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/support/import/:samplingPoint",
    component: () => import("@/views/support/SamplingPointMuestras.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/support/GraphClient",
    component: () => import("@/views/support/GraphClient.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    path: "/support/validation",
    component: () => import("@/views/support/Validate_2.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    name: "support-satelital",
    path: "/support/satelite",
    component: () => import("@/views/support/Satelital.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    name: "SamplingPointMuestras",
    path: "/support/uploadHistory",
    component: () => import("@/views/support/SamplingPointMuestras.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
  {
    name: "SamplingPointGallery",
    path: "/support/gallery",
    component: () => import("@/views/support/SamplingPointGallery.view.vue"),
    meta: {
      layout: "LayoutDefault",
      sidebar: sidebarName,
    },
  },
];
