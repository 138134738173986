import store from "./../store";
import { createRouter, createWebHistory, isNavigationFailure } from "vue-router";
import { adminRoutes } from "./admin.router";
import { stationsRoutes } from "./stations.router";
import { supportRoutes } from "./support.router";
import { AppError, AppType } from "./../types/app.type";
import securityService from "./../service/security.service";
import NotFound from "./../views/NotFound.view.vue";
import { errorHandlerDefault } from "./../utils/handler.util";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/Login.view.vue"),
      meta: {
        layout: "LayoutEmpty",
      },
    },
    {
      path: "/home",
      name: "Home",
      component: () => import("@/views/common/Home.view.vue"),
      meta: {
        layout: "LayoutDefault",
      },
    },
    ...adminRoutes,
    ...supportRoutes,
    ...stationsRoutes,

    {
      path: "/:catchAll(.*)",
      name: "NotFound",
      component: NotFound,
      meta: {
        layout: "LayoutEmpty",
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("user");

  if (!loggedIn && to.path !== "/login") {
    next("/login"); // Redirige a /login si no está autenticado
    return;
  }

  to.meta.from = from;
  // - check access to app
  if (to.name === "Home") {
    const canAccessAdmin = securityService.canAccessToApp(AppType.ADMIN);
    const canAccessSupport = securityService.canAccessToApp(AppType.SUPPORT);
    const canAccessStations = securityService.canAccessToApp(AppType.STATIONS);

    if (Number(canAccessAdmin) + Number(canAccessSupport) + Number(canAccessStations) === 1) {
      // - access only one app
      if (canAccessAdmin) {
        next("/admin");
        return;
      } else if (canAccessSupport) {
        next("/support");
        return;
      } else if (canAccessStations) {
        next("/stations");
        return;
      }
    }
  }

  // - check path security
  if (to.name !== "NotFound" && !securityService.canAccessToPath(to.path)) {
    errorHandlerDefault(AppError.ACCESS_DENIED);
    next(loggedIn ? securityService.HOME_PAGE : securityService.LOGIN_PAGE);
    return;
  }

  next();
});

router.afterEach((to, from, failure) => {
  if (!isNavigationFailure(failure)) {
    // - set current app
    let app;
    if (securityService.isAdminPath(to.path)) {
      app = AppType.ADMIN;
    } else if (securityService.isSupportPath(to.path)) {
      app = AppType.SUPPORT;
    } else if (securityService.isStationsPath(to.path)) {
      app = AppType.STATIONS;
    }
    store.commit("ui/setApp", app);
  }
});

export default router;
